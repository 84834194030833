import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'pm-yes-no-dialog',
    template: `
        <pro-dialog-title [title]="data.header || 'action_approval'"></pro-dialog-title>
        <div mat-dialog-content>
            <p>{{ data.question | translate }}</p>
        </div>
        <div class="fl fl-row">
            <pro-btn (onClick)="yesAction()" [label]="data.yesBtnLabel ?? 'yes'" [theme]="'accent'"></pro-btn>
            <pro-btn (onClick)="noAction()" [label]="data.noBtnLabel ?? 'no'" [theme]="'accent'"></pro-btn>
        </div>
    `
})

export class YesNoDialogComponent {
    constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: any, public dialogRef: MatLegacyDialogRef<YesNoDialogComponent>) {}

    confirm() {

        if (this.data.parameters) {
            const data = this.data.parameters.reduce((accumulator: any, item: any) => {
                accumulator[item.key] = item.value;

                return accumulator;
            }, {});

            this.dialogRef.close(data);
        } else {
            this.dialogRef.close(1);

        }

    }

    yesAction() {
        this.dialogRef.close(1);
    }

    noAction() {
        this.dialogRef.close(0);
    }
}
